<template>
  <div class="fin-de-contrat">
    <Banner>
      <div class="banner-content">
        <MeepIconSocial class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.socials") }}</span>
      </div>
    </Banner>

    <div
      :class="{ 'fin-de-contrat-content--lcm': isLCM }"
      class="fin-de-contrat-content page-layout"
    >
      <LoadingCard v-if="isLoading" />

      <md-card v-else class="meep-form">
        <PageHeader
          :has-actions="false"
          :has-back="true"
          :has-search="false"
          :title="$t('socials.reprise.title')"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <!-- Numéro de SIREN -->
          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">1</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.fin.section-1-title") }}
              </p>
            </div>

            <!-- Company Select -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.company") }}
                </label>

                <md-select v-model="$v.form.companyId.$model">
                  <md-option
                    v-for="(company, index) in companies"
                    :key="index"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
                <template v-if="$v.form.companyId.$dirty">
                  <span v-show="!$v.form.companyId.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>
            <!-- Employee Select -->
            <div v-if="this.form.companyId" class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.employee") }}
                </label>

                <md-select
                  v-model="form.employee"
                  @md-selected="changeEmployee"
                >
                  <md-option
                    v-for="(employee, index) in companyEmployees"
                    :key="index"
                    :value="index"
                  >
                    {{ employee.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <!-- Last Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.lastName") }}</label>
                <md-input v-model="$v.form.lastName.$model"></md-input>
              </md-field>
              <template v-if="$v.form.lastName.$dirty">
                <span v-show="!$v.form.lastName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.lastName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- First Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.firstName") }}</label>
                <md-input v-model="$v.form.firstName.$model"></md-input>
              </md-field>
              <template v-if="$v.form.firstName.$dirty">
                <span v-show="!$v.form.firstName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.firstName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- Caracteristique -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.reprise.characterisque") }}</label>
                <md-input v-model="$v.form.caracteristique.$model"></md-input>
              </md-field>
              <template v-if="$v.form.caracteristique.$dirty">
                <span
                  v-show="!$v.form.caracteristique.required"
                  class="meep-error"
                >
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- New Date -->
            <div class="meep-input">
              <md-datepicker v-model="form.newDate">
                <label>
                  {{ $t("socials.reprise.new-date") }}
                </label>
              </md-datepicker>
            </div>
          </div>

          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">2</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.fin.section-2-title") }}
              </p>
            </div>
            <GetPicture
              ref="picture"
              :nameGenerator="generateImageName"
              @change="changeImage"
            />
            <div class="md-layout md-alignment-center-right create-button">
              <md-button class="md-raised md-primary" @click.native="save">
                {{ $t("socials.button-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import companiesModel from "../../model/companies";
import employeeModel from "../../model/employee";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconSocial from "@/components/icons/MeepIconSocial.vue";
import LoadingCard from "@/components/LoadingCard";
import GetPicture from "@/components/GetPicture.vue";
import { motifContrat, SOCIALS } from "../../constants";
import { alphaSpace } from "../../services/vuelidate";
import { required } from "vuelidate/lib/validators";
import { createFileObject, filterEmployeesCompanyId } from "@/services/util";
import FilesModel from "@/model/files";

export default {
  name: "FinDeContrat",

  components: {
    LoadingCard,
    Banner,
    MeepIconSocial,
    PageHeader,
    GetPicture,
  },
  computed: {
    ...mapGetters(["isJEPA", "isJEP", "isLCM"]),
    companyEmployees() {
      return filterEmployeesCompanyId(this.employees, this.form.companyId);
    },
  },

  data() {
    return {
      groups: [],
      isLoading: false,
      form: {
        employee: null,
        lastName: null,
        firstName: null,
        caracteristique: null,
        newDate: null,
        type: SOCIALS.REPRISE,
        companyId: null,
        justificatif: null,
      },
      fileName: null,
      companies: [],
      employees: [],
      motifContrat,
    };
  },

  validations: {
    form: {
      firstName: {
        alphaSpace,
        required,
      },
      lastName: {
        alphaSpace,
        required,
      },
      companyId: {
        required,
      },
      caracteristique: {
        required,
      },
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    generateImageName() {
      return `Reprise-anticipee--${Date.now()}.jpg`;
    },

    changeImage(imageName) {
      this.fileName = imageName;
      this.form.justif = imageName;
    },

    goBack() {
      window.history.back();
    },

    async loadData() {
      try {
        this.isLoading = true;
        this.companies = await companiesModel.getAll();
        this.employees = await employeeModel.getAllEmployee();

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },

    async save() {
      const dataObject = { data: this.form };
      const result = !this.$v.$invalid;
      if (result) {
        try {
          if (this.fileName) {
            const file = this.$refs.picture.$data.file;
            createFileObject(dataObject, file, this.fileName);

            await FilesModel.uploadDsn(dataObject);
          } else await FilesModel.uploadDsn(dataObject);

          this.$toasted.global.AppSucces({
            message: this.$t("socials.reprise.success"),
          });

          this.$router.push("/dashboard/sociale/");
        } catch (error) {
          this.$toasted.global.AppError({
            message: error.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },

    changeEmployee(index) {
      this.form.lastName = this.companyEmployees[index].value.lastName;
      this.form.firstName = this.companyEmployees[index].value.firstName;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .fin-de-contrat {
    .meep-form-column {
      display: flex;
      flex-direction: column;
    }

    .get-picture-container {
      margin: 0;
    }

    .fin-de-contrat-content .md-card.meep-form .md-card-content {
      align-items: unset;
    }

    &-content {
      padding: toRem(30);
      .md-card.meep-form {
        @include for-lg {
          padding: toRem(34) toRem(46) toRem(46) toRem(50);
        }
        .page-header {
          &__item {
            margin-bottom: 0;
          }
        }

        .md-card-content {
          .socials-form {
            .meep-form-column-title {
              margin: toRem(25) 0 toRem(12) 0;
              @include for-lg {
                margin: toRem(32) 0 toRem(16) 0;
              }
            }
            .create-button {
              margin: auto 0 0 0;
              .md-button {
                margin-right: 0;
                font-size: toRem(9);
                @include for-lg {
                  font-size: toRem(14);
                }
              }
            }
            .meep-input {
              .md-field {
                margin: toRem(7) 0 0 0;
                @include for-lg {
                  margin: toRem(10) 0 0 0;
                }
              }
            }
            &:first-child {
              .meep-form-column-title {
                margin-bottom: toRem(5);
                @include for-lg {
                  margin: toRem(32) 0 toRem(11) 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
